import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import moment from 'moment';
import * as _ from 'underscore';
import * as XLSX from 'xlsx';
import { DataService } from './firebase.service';
import { FirestoreService } from './firestore.service';

type AOA = any[][];

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  constructor(private ds: DataService, public fs: FirestoreService) {}
  exportAsExcelFile(json: any[], object: string, cols?: any[]) {
    let defs = [];
    switch (object) {
      case 'classes':
        defs = this.definitionClasses();
        break;
      case 'class-members':
        defs = this.definitionClassMembers();
        break;
      case 'persons':
        defs = this.definitionPersons();
        break;
      case 'presence':
        defs = this.definitionPresence();
        break;
      case 'analytics-presence':
        defs = this.definitionPresence();
        break;
      case 'analytics-presencesocialsecurityoffice':
        defs = this.definitionPresence();
        break;
      case 'analytics-classperson':
        defs = this.definitionAnalyticsClassPerson();
        break;
      case 'analytics-recommendation':
        defs = this.definitionAnalyticsRecommendation();
        break;

      case 'analytics-childrens':
        defs = this.definitionAnalyticsChildrens();
        break;
      case 'analytics-competenceprofiles':
        defs = this.definitionAnalyticsCompetenceprofiles();
        break;
      case 'analytics-dropouts':
        defs = this.definitionAnalyticsDropouts();
        break;
      case 'analytics-personcompetence':
        defs = this.definitionAnalyticsPersoncompetence();
        break;
      case 'analytics-semesterchange':
        defs = this.definitionAnalyticsSemesterchange();
        break;
      case 'analytics-supervisors':
        defs = this.definitionAnalyticsSupervisors();
        break;
      case 'analytics-teachers':
        defs = this.definitionAnalyticsTeachers();
        break;
      case 'analytics-waitinglist':
        defs = this.definitionAnalyticsWaitinglist();
        break;

      case 'placementtests':
        defs = this.definitionPlacementtests();
        break;
      case 'waitinglists':
        defs = this.definitionWaitinglists();
        break;
      case 'waitinglist-members':
        defs = this.definitionWaitinglistMembers();
        break;
      case 'placementtest-members':
        defs = this.definitionPlacementtestMembers();
        break;
      case 'modules':
        defs = this.definitionModules();
        break;
      case 'courses':
        defs = this.definitionCourses();
        break;

      // Interpret
      case 'orders':
        defs = this.definitionOrders();
        break;
      case 'availability':
        defs = this.definitionAvailability();
        break;
      case 'interpreters':
        defs = this.definitionInterpreter();
        break;
      case 'customers':
        defs = this.definitionCustomer();
        break;
    }

    console.log('defs', defs);

    if (cols) {
      for (const col of cols) {
        col.titel = col.name.substr(8, 2) + '.' + col.name.substr(5, 2) + '.' + col.name.substr(0, 4);

        switch (col.name.substr(11, 1)) {
          case '1':
            col.titel = col.titel + ' Vorm.';
            col.sort = col.name.substr(0, 11) + '1';
            break;
          case '2':
            col.titel = col.titel + ' Nachm.';
            col.sort = col.name.substr(0, 11) + '3';
            break;
          case '3':
            col.titel = col.titel + ' Abend.';
            col.sort = col.name.substr(0, 11) + '5';
            break;
          case '4':
            col.titel = col.titel + ' Vorm. 2';
            col.sort = col.name.substr(0, 11) + '2';
            break;
          case '5':
            col.titel = col.titel + ' Nachm. 2';
            col.sort = col.name.substr(0, 11) + '4';
            break;
        }
      }
    }

    cols = _.chain(cols)
      .sortBy('sort')
      .value();

    defs.push.apply(defs, cols);

    const promises = [];

    console.log('loop at json');
    for (const entry of json) {
      const row = this.getRow(entry, defs);
      promises.push(row);
    }

    Promise.all(promises)
      .then((data) => {
        data.unshift(
          defs.map((def) => {
            return def.titel;
          })
        );
        // console.log(data)

        /*
      // Load a new blank workbook
      XlsxPopulate.fromBlankAsync()
      .then(workbook => {
          // Modify the workbook.
          let range = workbook.sheet(0).cell("A1").value(data);

          console.log(range);
          range.style("fill", "0000ff");
          // Write to file.
          return workbook.outputAsync({type: null}).then(excel => {

            FileSaver.saveAs(excel, '111_export_'+object+'_' + new Date().getTime()+'.xlsx');
          });

      });*/

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Export');

        /* save to file */
        XLSX.writeFile(wb, 'export_' + object + '_' + new Date().getTime() + '.xlsx');
      })
      .catch((err) => {
        console.log(err);
      });
  }
  private getRow(entry, defs): Promise<any> {
    const promises = [];
    for (const def of defs) {
      const value = this.getValue(def, entry);
      promises.push(value);
    }

    return Promise.all(promises);
    // return Promise.resolve(row);
  }

  private definitionClasses() {
    return [
      { name: 'yearsemester', object: null, titel: 'Semester' },
      { name: 'module', object: 'module', titel: 'Modul' },
      { name: 'name', object: null, titel: 'Name' },
      { name: 'key', object: null, titel: 'Kurzzeichen' },
      { name: 'teacher', object: 'teacher', titel: 'Lehrperson' },
      { name: 'teacher2', object: 'teacher', titel: 'Lehrperson 2' },
      { name: 'teacher3', object: 'teacher', titel: 'Lehrperson 3' },
      { name: 'teacher4', object: 'teacher', titel: 'Lehrperson 4' },
      { name: 'teacher5', object: 'teacher', titel: 'Lehrperson 5' },
      { name: 'teacher6', object: 'teacher', titel: 'Lehrperson 6' },
      { name: 'target', object: null, titel: 'Zielgruppe' },
      { name: 'startLevel', object: null, titel: 'Start Niveau' },
      { name: 'targetLevel', object: null, titel: 'Ziel Niveau' }
    ];
  }

  private definitionClassMembers() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'language', object: 'language', titel: 'Sprache' },
      { name: 'age', object: 'age', titel: 'Alter' },
      { name: 'sex', object: null, titel: 'Geschlecht' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' }
    ];
  }

  private definitionPersons() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'language', object: 'language', titel: 'Sprache' },
      { name: 'birthdate', object: 'date', titel: 'Geb.Datum' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'kibis', object: null, titel: 'Kibis' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' }
    ];
  }

  private definitionPresence() {
    return [
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'unexcused', object: 'unexcused', titel: 'Unentschuldigt' },
      { name: 'attendance', object: 'attendance', titel: 'Anwesenheit' },
      { name: 'class', object: 'class', titel: 'Klasse' }
    ];
  }

  private definitionAnalyticsClassPerson() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'zar', object: null, titel: 'Zar' },
      { name: 'birthdate', object: 'date', titel: 'Geb.Datum' },
      { name: 'age', object: 'age', titel: 'Alter' },
      { name: 'kibis', object: null, titel: 'Kibis' },
      { name: 'language', object: 'language', titel: 'Sprache' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'dateofimmigration', object: 'date', titel: '' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'year', object: 'classdetail-year', titel: 'Jahr' },
      { name: 'semester', object: 'classdetail-semester', titel: 'Semester' },
      { name: 'module', object: 'classperson-module', titel: 'Modul' },
      { name: 'class', object: 'classperson-class', titel: 'Klasse' },
      { name: 'active', object: 'classperson-active', titel: 'Aktiv' }
    ];
  }

  private definitionAnalyticsRecommendation() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'birthdate', object: 'date', titel: 'Geb.Datum' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'class', object: 'classperson-class', titel: 'Klasse' },
      { name: 'teacher', object: 'classdetails-teacher', titel: 'Lehrperson' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'comment', object: 'competenceprofile-comment', titel: 'Bemerkung an Betreuer / Selbstzahlende' },
      {
        name: 'recommendationLernangebot',
        object: 'competenceprofile-recommendationLernangebot',
        titel: 'Empfehlung Lernangebot'
      },
      {
        name: 'recommendationNiveau',
        object: 'competenceprofile-recommendationNiveau',
        titel: 'Empfehlung Klassenniveau'
      }
    ];
  }

  private definitionPlacementtests() {
    return [
      { name: 'key', object: null, titel: 'Kurzzeichen' },
      { name: 'name', object: null, titel: 'Bezeichnung' },
      { name: 'testdate', object: 'date', titel: 'Testdatum' }
    ];
  }

  private definitionWaitinglists() {
    return [
      { name: 'module', object: 'module', titel: 'Modul' },
      { name: 'name', object: null, titel: 'Name' },
      { name: 'key', object: null, titel: 'Kurzzeichen' },
      { name: 'startLevel', object: null, titel: 'Start Niveau' },
      { name: 'targetLevel', object: null, titel: 'Ziel Niveau' }
    ];
  }
  private definitionWaitinglistMembers() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'age', object: null, titel: 'Alter' },
      { name: 'sex', object: null, titel: 'Geschlecht' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' }
    ];
  }

  private definitionPlacementtestMembers() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'age', object: 'age', titel: 'Alter' },
      { name: 'sex', object: null, titel: 'Geschlecht' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' }
    ];
  }
  private definitionCourses() {
    return [
      { name: 'name', object: null, titel: 'Name' },
      { name: 'key', object: null, titel: 'Kurzzeichen' }
    ];
  }
  private definitionModules() {
    return [
      { name: 'course', object: 'course', titel: 'Fachbereich' },
      { name: 'name', object: null, titel: 'Name' },
      { name: 'key', object: null, titel: 'Kurzzeichen' }
    ];
  }

  private definitionAnalyticsWaitinglist() {
    return [
      { name: 'module', object: 'waitinglist-module', titel: 'Lernangebot' },
      { name: 'key', object: 'waitinglist-key', titel: 'Kurz Zeichen' },
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'language', object: 'language', titel: 'Sprache' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'birthdate', object: 'date', titel: 'Geb. Datum' },
      { name: 'age', object: 'age', titel: 'Alter' },
      { name: 'sex', object: null, titel: 'Geschlecht' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' }
    ];
  }

  private definitionAnalyticsChildrens() {
    return [
      { name: 'tnfirstname', object: null, titel: 'Vorname TN' },
      { name: 'tnlastname', object: null, titel: 'Nachname TN' },
      { name: 'firstname', object: null, titel: 'Vorname K.' },
      { name: 'lastname', object: null, titel: 'Nachname K.' },
      { name: 'birthdate', object: 'date', titel: 'Geb. Datum' },
      { name: 'age', object: 'age', titel: 'Alter' },
      { name: 'kibis', object: null, titel: 'Kibis' },
      { name: 'tnlanguage', object: 'language', titel: 'Sprache' },
      { name: 'tnnationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'doctor', object: null, titel: 'Kinderarzt' },
      { name: 'tntelefon', object: null, titel: 'Tel.' },
      { name: 'tnmobile', object: null, titel: 'Mobile' },
      { name: 'tnemail', object: null, titel: 'eMail' },
      { name: 'tnstreet', object: null, titel: 'Strasse' },
      { name: 'tncity', object: null, titel: 'Ort' },
      { name: 'tnpostalCode', object: null, titel: 'PLZ' },
      { name: 'tnsupervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'tnremarks', object: null, titel: 'Kommentar' }
    ];
  }

  private definitionAnalyticsCompetenceprofiles() {
    return [
      { name: 'dateofevaluation', object: 'date', titel: 'Datum der Evaluation' },
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'birthdate', object: 'date', titel: 'Geb. Datum' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'tnclass', object: 'class', titel: 'Klasse' },
      { name: 'evaluator', object: 'teacher', titel: 'Beurteilung durch' },

      {
        name: 'stufenderalphabetisierung.hoerenverstehen.evaluation',
        object: 'deep',
        titel: 'Aphabetisierung / Hören / Verstehen'
      },
      { name: 'stufenderalphabetisierung.hoerenverstehen.comment', object: 'deep', titel: 'Kommentar' },

      {
        name: 'stufenderalphabetisierung.sprechen.evaluation',
        object: 'deep',
        titel: 'Aphabetisierung / Hören / Verstehen'
      },
      { name: 'stufenderalphabetisierung.sprechen.comment', object: 'deep', titel: 'Kommentar' },

      {
        name: 'stufenderalphabetisierung.lesen.evaluation',
        object: 'deep',
        titel: 'Aphabetisierung / Hören / Verstehen'
      },

      { name: 'stufenderalphabetisierung.schreiben.evaluation', object: 'deep', titel: 'Aphabetisierung / Schreiben' },
      { name: 'stufenderalphabetisierung.schreiben.comment', object: 'deep', titel: 'Kommentar' },

      { name: 'deutschniveau.hoerenverstehen.evaluation', object: 'deep', titel: 'Deutschniveau / Hören / Verstehen' },
      { name: 'deutschniveau.hoerenverstehen.comment', object: 'deep', titel: 'Kommentar' },

      { name: 'deutschniveau.sprechen.evaluation', object: 'deep', titel: 'Deutschniveau / Sprechen' },
      { name: 'deutschniveau.sprechen.comment', object: 'deep', titel: 'Kommentar' },

      { name: 'deutschniveau.schreiben.evaluation', object: 'deep', titel: 'Deutschniveau / Schreiben' },
      { name: 'deutschniveau.schreiben.comment', object: 'deep', titel: 'Kommentar' },

      { name: 'mathe.general.evaluation', object: 'deep', titel: 'Mathe' },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.konzentrationundausdauer',
        object: 'deep',
        titel: 'Konzentration und Ausdauer'
      },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.beteiligtsichaktivamunterricht',
        object: 'deep',
        titel: 'beteiligt sich aktiv am Unterricht'
      },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.arbeitetausdauernd',
        object: 'deep',
        titel: 'arbeitet ausdauernd'
      },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.arbeitetselbststaendig',
        object: 'deep',
        titel: 'arbeitet selbstständig'
      },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.arbeitetsorgfaeltig',
        object: 'deep',
        titel: 'arbeitet sorgfältig'
      },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.arbeitetzuverlaessig',
        object: 'deep',
        titel: 'arbeitet zuverlässig'
      },

      {
        name: 'lernundlebenssituation.sozialkompetenzundsozialverhalten.arbeitetmitanderenkonstruktivzusammen',
        object: 'deep',
        titel: 'arbeitet mit anderen konstruktiv'
      },
      {
        name:
          'lernundlebenssituation.sozialkompetenzundsozialverhalten.haeltsichandieregelndesschulischenzusammenlebens',
        object: 'deep',
        titel: 'hält sich an die Regeln des schulischen Zusammenlebens'
      },
      {
        name:
          'lernundlebenssituation.sozialkompetenzundsozialverhalten.begegnetdenlehrpersonenmitschuelerinnenundmitschuelern',
        object: 'deep',
        titel: 'begegnet den Lehrpersonen, Mitschülerinnen und Mitschülern mit Respekt'
      },

      {
        name: 'empfehlunglernangebotdurchlehrperson',
        object: 'competenceprofile-module',
        titel: 'Empfehlung Lernangebot durch Lehrperson'
      },
      {
        name: 'empfehlung.empfehlungklassenniveaudurchlehrperson',
        object: 'deep',
        titel: 'Empfehlung Klassenniveau durch Lehrperson'
      },

      { name: 'zufriedenheit.bemerkungteilnehmerin', object: 'deep', titel: 'Bemerkung TeilnehmerIn' },
      { name: 'zufriedenheit.bemerkunglehrperson', object: 'deep', titel: 'Bemerkung Lehrperson' },
      { name: 'zufriedenheit.bemerkunganbetreuer', object: 'deep', titel: 'Bemerkung an Betreuer' },

      { name: 'stufenderalphabetisierung.hoerenverstehen.lautsicherheit', object: 'deep', titel: 'Lautsicherheit' },
      { name: 'stufenderalphabetisierung.hoerenverstehen.verstaendnis', object: 'deep', titel: 'Verstaendnis' },
      { name: 'stufenderalphabetisierung.hoerenverstehen.silbenverbindung', object: 'deep', titel: 'Silbenverbindung' },

      { name: 'stufenderalphabetisierung.sprechen.aussprache', object: 'deep', titel: 'Aussprache' },
      { name: 'stufenderalphabetisierung.sprechen.basiswortschatz', object: 'deep', titel: 'Basiswortschatz' },
      { name: 'stufenderalphabetisierung.schreiben.lautsicherheit', object: 'deep', titel: 'Lautsicherheit' },
      { name: 'stufenderalphabetisierung.schreiben.tempo', object: 'deep', titel: 'Tempo' },
      { name: 'stufenderalphabetisierung.schreiben.silbenverbindung', object: 'deep', titel: 'Silbenverbindung' },
      { name: 'deutschniveau.hoerenverstehen.globalesverstehen', object: 'deep', titel: 'Globalesverstehen' },
      { name: 'deutschniveau.hoerenverstehen.selektivesverstehen', object: 'deep', titel: 'Selektivesverstehen' },
      { name: 'deutschniveau.hoerenverstehen.detailverstehen', object: 'deep', titel: 'Detailverstehen' },
      { name: 'deutschniveau.sprechen.aussprache', object: 'deep', titel: 'Aussprache' },
      { name: 'deutschniveau.sprechen.formalerichtigkeit', object: 'deep', titel: 'Formalerichtigkeit' },
      { name: 'deutschniveau.sprechen.wortwahl', object: 'deep', titel: 'Wortwahl' },
      { name: 'deutschniveau.lesenverstehen.fluessigkeit', object: 'deep', titel: 'Fluessigkeit' },
      { name: 'deutschniveau.lesenverstehen.globalesverstehen', object: 'deep', titel: 'Globalesverstehen' },
      { name: 'deutschniveau.lesenverstehen.selektivesverstehen', object: 'deep', titel: 'Selektives Verstehen' },
      { name: 'deutschniveau.lesenverstehen.detailverstehen', object: 'deep', titel: 'etail Verstehen' },
      { name: 'deutschniveau.lesenverstehen.comment', object: 'deep', titel: 'Kommentar' },
      { name: 'deutschniveau.schreiben.formalerichtigkeit', object: 'deep', titel: 'Formale Richtigkeit' },
      { name: 'deutschniveau.schreiben.wortwahl', object: 'deep', titel: 'Wortwahl' },
      {
        name: 'lernundlebenssituation.sachkompetenzlernverhalten.auffassungsgabe',
        object: 'deep',
        titel: 'Auffassungsgabe'
      },
      {
        name: 'lernundlebenssituation.sachkompetenzlernverhalten.konzentrationundausdauer',
        object: 'deep',
        titel: 'Konzentration und Ausdauer'
      },
      { name: 'lernundlebenssituation.sachkompetenzlernverhalten.comment', object: 'deep', titel: 'Kommentar' },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.interesseundmotivation',
        object: 'deep',
        titel: 'Interesse und Motivation'
      },
      {
        name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.selbstvertrauenmitdersprache',
        object: 'deep',
        titel: 'Selbstvertrauen mit der Sprache'
      },
      { name: 'lernundlebenssituation.selbstkompetenzundarbeitshaltung.comment', object: 'deep', titel: 'Kommentar' },
      {
        name: 'lernundlebenssituation.sozialkompetenzundsozialverhalten.verhaltenindergruppe',
        object: 'deep',
        titel: 'Verhalten in der Gruppe'
      },
      { name: 'lernundlebenssituation.sozialkompetenzundsozialverhalten.comment', object: 'deep', titel: 'Kommentar' },
      {
        name: 'selbstbeurteilunglernundlebenssituation.lernundlebenssituation.familiaeresituation',
        object: 'deep',
        titel: 'Familiäre Situation'
      },
      {
        name: 'selbstbeurteilunglernundlebenssituation.lernundlebenssituation.beruflichesituation',
        object: 'deep',
        titel: 'Berufliche Situation'
      },
      {
        name: 'selbstbeurteilunglernundlebenssituation.lernundlebenssituation.zeitlichekapazitaetzumlernen',
        object: 'deep',
        titel: 'Zeitliche Kapazitaet zum Lernen'
      },
      {
        name: 'selbstbeurteilunglernundlebenssituation.lernundlebenssituation.anwendungimalltag',
        object: 'deep',
        titel: 'Anwendung im Alltag'
      },
      {
        name: 'selbstbeurteilunglernundlebenssituation.lernundlebenssituation.persoenlicheziele',
        object: 'deep',
        titel: 'Persoenliche Ziele'
      },
      {
        name: 'selbstbeurteilunglernundlebenssituation.lernundlebenssituation.comment',
        object: 'deep',
        titel: 'Kommentar'
      },
      { name: 'selbsbeurteilunglernfortschritt.sprechen', object: 'deep', titel: 'Sprechen' },
      { name: 'selbsbeurteilunglernfortschritt.hoerenverstehen', object: 'deep', titel: 'Hoeren / Verstehen' },
      { name: 'selbsbeurteilunglernfortschritt.lesenverstehen', object: 'deep', titel: 'Lesen / Verstehen' },
      { name: 'selbsbeurteilunglernfortschritt.schreiben', object: 'deep', titel: 'Schreiben' },
      { name: 'selbsbeurteilunglernfortschritt.comment', object: 'deep', titel: 'Kommentar' },
      { name: 'zufriedenheit.anbieter.generellezufriedenheit', object: 'deep', titel: 'Generelle Zufriedenheit' },
      { name: 'zufriedenheit.anbieter.kinderbetreuung', object: 'deep', titel: 'Kinderbetreuung' },
      { name: 'zufriedenheit.anbieter.infrastruktur', object: 'deep', titel: 'Infrastruktur' },
      { name: 'zufriedenheit.anbieter.comment', object: 'deep', titel: 'Kommentar' },
      { name: 'zufriedenheit.lernangebot.klasse', object: 'deep', titel: 'Klasse' },
      { name: 'zufriedenheit.lernangebot.niveau', object: 'deep', titel: 'Niveau' },
      { name: 'zufriedenheit.lernangebot.lernmethoden', object: 'deep', titel: 'Lernmethoden' },
      { name: 'zufriedenheit.lernangebot.lernmaterial', object: 'deep', titel: 'Lernmaterial' },
      { name: 'zufriedenheit.lernangebot.lernthemen', object: 'deep', titel: 'Lernthemen' },
      { name: 'zufriedenheit.lernangebot.lerntempo', object: 'deep', titel: 'Lerntempo' },
      { name: 'zufriedenheit.lernangebot.hausaufgaben', object: 'deep', titel: 'Hausaufgaben' },
      { name: 'zufriedenheit.lernangebot.comment', object: 'deep', titel: 'Kommentar' }
    ];
  }

  private definitionAnalyticsDropouts() {
    return [
      { name: 'year', object: 'classdetail-year', titel: 'Jahr' },
      { name: 'semester', object: 'classdetail-semester', titel: 'Semester' },
      { name: 'module', object: 'classperson-module', titel: 'Modul' },
      { name: 'class', object: 'classperson-class', titel: 'Klasse' },
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'language', object: 'language', titel: 'Sprache' },
      { name: 'nationality', object: 'nationality', titel: 'Nationalität' },
      { name: 'birthdate', object: 'date', titel: 'Geb.Datum' },
      { name: 'age', object: 'age', titel: 'Alter' },
      { name: 'sex', object: null, titel: 'Geschlecht' },
      { name: 'residencestatus', object: 'residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'supervisor', object: 'supervisor', titel: 'Betreuungsperson' },
      { name: 'foreign.classperson.dropoutdate', object: 'deep', titel: 'Dropout Datum' },
      { name: 'foreign.classperson.dropoutreason', object: 'deep', titel: 'Dropout Grund' }
    ];
  }

  private definitionAnalyticsPersoncompetence() {
    return [
      { name: 'foreign.personDetails.firstname', object: 'deep', titel: 'Vorname' },
      { name: 'foreign.personDetails.lastname', object: 'deep', titel: 'Nachname' },
      { name: 'foreign.personDetails.birthdate', object: 'deep', titel: 'Geb.Datum' },
      { name: 'supervisor', object: 'foreign-personDetails-supervisor', titel: 'Betreuungsperson' },
      { name: 'module', object: 'foreign-classDetails-module', titel: 'Modul' },
      { name: 'class', object: 'foreign-classperson-class', titel: 'Klasse' },
      { name: 'teacher', object: 'foreign-classDetails-teacher', titel: 'Lehrer' },
      { name: 'foreign.personDetails.kibis', object: 'deep', titel: 'Kibis' },
      { name: 'lerngewohntheit', object: null, titel: 'Lerngewohntheit' },
      { name: 'stufederalphabetisierung', object: null, titel: 'Alphabetisierung' },
      { name: 'deutschniveau', object: null, titel: 'Deutschniveau' },
      { name: 'lernundLebenssituation', object: null, titel: 'Lern- und Lebenssituation' },
      { name: 'selbsbeurteilunglernfortschritt', object: null, titel: 'Selbsbeurteilung Lernfortschritt' },
      { name: 'zufriedenheit', object: null, titel: 'Zufriedenheit' },
      { name: 'praesenz', object: null, titel: 'Präsenz' },
      { name: 'empfehlunglernangebot', object: 'module', titel: 'Empfehlung Lernangebot' },
      { name: 'empfehlungklassenniveau', object: null, titel: 'Empfehlung Sprachniveau' },
      { name: 'changedate', object: 'date', titel: 'Aktualisiert am' }
    ];
  }

  private definitionAnalyticsSemesterchange() {
    return [
      { name: 'firstname', object: 'person', titel: 'Vorname' },
      { name: 'lastname', object: 'person', titel: 'Nachname' },
      { name: 'birthdate', object: 'person', titel: 'Geb.Datum' },
      { name: 'residencestatus', object: 'person-residencestatus', titel: 'Aufenthaltsstatus' },
      { name: 'supervisor', object: 'person-supervisor', titel: 'Betreuungsperson' },
      { name: 'kibis', object: 'person', titel: 'Kibis' },

      { name: 'lerngewohntheit', object: 'personCompetence', titel: 'Lerngewohntheit' },
      { name: 'stufederalphabetisierung', object: 'personCompetence', titel: 'Stufe der Alphabetisierung' },
      { name: 'deutschniveau', object: 'personCompetence', titel: 'Deutschniveau' },
      { name: 'lernundLebenssituation', object: 'personCompetence', titel: 'Lern und Lebenssituation' },
      { name: 'selbsbeurteilunglernfortschritt', object: 'personCompetence', titel: 'Selbstb. Lernfortschritt' },
      { name: 'zufriedenheit', object: 'personCompetence', titel: 'Zufriedenheit' },
      { name: 'praesenz', object: 'personCompetence', titel: 'Präsenz' },

      { name: 'waitinglist', object: 'waitinglist-list', titel: 'Warteliste' },

      { name: 'currentClassModule', object: 'currentClassModule-list', titel: 'Lernangebot ausgewähltes Semester' },
      { name: 'currentClass', object: 'currentClass-list', titel: 'Klasse ausgewähltes Semester' },
      { name: 'currentClassTeacher', object: 'currentClassTeacher-list', titel: 'Lehrer ausgewähltes Semester' },

      {
        name: 'empfehlunglernangebot',
        object: 'personCompetence-empfehlunglernangebot',
        titel: 'Empfehlung Lernangebot für folgendes Semester'
      },
      {
        name: 'empfehlungklassenniveau',
        object: 'personCompetence',
        titel: 'Empfehlung Sprachniveau für folgendes Semester'
      },
      { name: 'changedate', object: 'personCompetence', titel: 'Änderungsdatum' },

      { name: 'nextClassModule', object: 'nextClassModule-list', titel: 'Lernangebot nächstes Semester' },
      { name: 'nextClass', object: 'nextClass-list', titel: 'Klasse nächstes Semester' },
      { name: 'nextClassTeacher', object: 'nextClassTeacher-list', titel: 'Lehrer nächstes Semester' }
    ];
  }
  private definitionOrders() {
    return [
      { name: 'id', object: null, titel: 'ID' },
      { name: '_customer.name', object: 'deep', titel: 'Name' },
      { name: '_customer.street', object: 'deep', titel: 'Strasse' },
      { name: '_customer.postalCode', object: 'deep', titel: 'Postleitzahl' },
      { name: '_customer.city', object: 'deep', ref: 'customer', attr: 'city', titel: 'Stadt' },
      { name: '_customer.abacusnr', object: 'deep', titel: 'Kundennummer' },
      { name: 'dateFrom', object: 'timestamp', titel: 'Datum' },
      { name: '_dolmetschCategory.title', object: 'deep', titel: 'Kategorie' },
      { name: '_dolmetschType.title', object: 'deep', titel: 'Typ' },
      { name: '_region.title', object: 'deep', titel: 'Region' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: '_language.language', object: 'deep', titel: 'Sprache' },
      { name: '_dolmetscher.firstname', object: 'deep', titel: 'Dolmetscher Vorname' },
      { name: '_dolmetscher.lastname', object: 'deep', titel: 'Dolmetscher Nachname' },
      { name: '_dolmetscher.employeenr', object: 'deep', titel: 'Mitarbeiternummer' },
      { name: '_dolmetscher.education', object: 'deep', titel: 'Ausbildung' },
      { name: 'durationDolmetsch', object: null, titel: 'Dauer - Dolmetschen' },
      { name: 'durationTravel', object: null, titel: 'Dauer - Reise' },
      { name: 'durationWait', object: null, titel: 'Dauer - Warten' },
      { name: 'expenseTravel', object: null, titel: 'Reisespesen' },
      { name: 'active', object: null, titel: 'Aktiv' },
      { name: 'statusExported', object: null, titel: 'Status Abgerechnet' },
      { name: 'statusExportedDate', object: 'timestamp', titel: 'Abgerechnet am' },
      { name: 'step', object: null, titel: 'Bemerkungen' },
      { name: 'noshow', object: null, titel: 'Kunde nicht erschienen' },
      { name: 'extraSheet', object: null, titel: 'Extrablatt' },
      { name: 'bereich', object: null, titel: 'Bereich' },
      { name: 'subbereich', object: null, titel: 'Subbereich' }
    ];
  }

  private definitionAvailability() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      {
        name: 'nativelanguage',
        object: 'dolmetscher-attr',
        ref: 'nativelanguage',
        attr: 'language',
        titel: '1. Sprache'
      },
      {
        name: 'secondlanguage',
        object: 'dolmetscher-attr',
        ref: 'secondlanguage',
        attr: 'language',
        titel: '2. Sprache'
      },
      {
        name: 'thirdlanguage',
        object: 'dolmetscher-attr',
        ref: 'thirdlanguage',
        attr: 'language',
        titel: '3. Sprache'
      },
      {
        name: 'fourthlanguage',
        object: 'dolmetscher-attr',
        ref: 'fourthlanguage',
        attr: 'language',
        titel: '4. Sprache'
      },
      { name: 'mo_am', object: null, titel: 'Mo Vorm.' },
      { name: 'mo_pm', object: null, titel: 'Mo Nachm' },
      { name: 'di_am', object: null, titel: 'Di Vorm' },
      { name: 'di_pm', object: null, titel: 'Di Nachm' },
      { name: 'mi_am', object: null, titel: 'Mi Vorm' },
      { name: 'mi_pm', object: null, titel: 'Mi Nachm' },
      { name: 'do_am', object: null, titel: 'Do Vorm' },
      { name: 'do_pm', object: null, titel: 'Do Nachm' },
      { name: 'fr_am', object: null, titel: 'Fr Vorm' },
      { name: 'fr_pm', object: null, titel: 'Fr Nachm' }
    ];
  }
  private definitionInterpreter() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'sex', object: null, titel: 'Geschlecht' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalcode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      {
        name: 'nativelanguage',
        object: 'dolmetscher-attr',
        ref: 'nativelanguage',
        attr: 'language',
        titel: '1. Sprache'
      },
      {
        name: 'secondlanguage',
        object: 'dolmetscher-attr',
        ref: 'secondlanguage',
        attr: 'language',
        titel: '2. Sprache'
      },
      {
        name: 'thirdlanguage',
        object: 'dolmetscher-attr',
        ref: 'thirdlanguage',
        attr: 'language',
        titel: '3. Sprache'
      },
      {
        name: 'fourthlanguage',
        object: 'dolmetscher-attr',
        ref: 'fourthlanguage',
        attr: 'language',
        titel: '4. Sprache'
      },
      { name: 'country', object: 'dolmetscher-attr', ref: 'country', attr: 'name', titel: 'Herkunftsland' },
      { name: 'educ_m0', object: null, titel: 'Einführungskurs' },
      { name: 'educ_m1', object: null, titel: 'Modul 1' },
      { name: 'educ_m2', object: null, titel: 'Modul 2' },
      { name: 'educ_m3', object: null, titel: 'Modul 3' },
      { name: 'educ_m4', object: null, titel: 'Modul 4' },
      { name: 'educ_m5', object: null, titel: 'Modul 5' },
      { name: 'educ_m6', object: null, titel: 'Modul 6' },
      { name: 'educ_m7', object: null, titel: 'Modul 7' },
      { name: 'educ_m8', object: null, titel: 'Modul 8' },
      { name: 'educ_m9', object: null, titel: 'Modul 9' },
      { name: 'educ_m10', object: null, titel: 'Modul 10' },
      { name: 'educ_telducto', object: null, titel: 'Telducto' },
      { name: 'educ_seminar1', object: null, titel: 'Seminar 1' },
      { name: 'educ_seminar2', object: null, titel: 'Seminar 1' },
      { name: 'employeenr', object: null, titel: 'Mitarbeiternummer' },
      { name: 'telefon', object: null, titel: 'Telefon' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'education', object: 'education', titel: 'Ausbildung' },
      { name: 'category', object: null, titel: 'Kategorie' },
      { name: 'dolmetscharttrialog', object: null, titel: 'Trialog' },
      { name: 'dolmetscharttelefong', object: null, titel: 'Telefon' },
      { name: 'dolmetschartbegleitauftrag', object: null, titel: 'Begleitauftrag' },
      { name: 'dolmetschartschriftlich', object: null, titel: 'Schriftliche Übersetzung' }
    ];
  }

  private definitionCustomer() {
    return [
      { name: 'name', object: null, titel: 'Firma' },
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalCode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'telefon', object: null, titel: 'Telefon' },
      { name: 'abacusnr', object: null, titel: 'Abacus Nr.' },
      { name: 'bereich', object: null, titel: 'Bereich' },
      { name: 'subbereich', object: null, titel: 'Subbereich' }
    ];
  }

  private definitionAnalyticsSupervisors() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalcode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' }
    ];
  }

  private definitionAnalyticsTeachers() {
    return [
      { name: 'firstname', object: null, titel: 'Vorname' },
      { name: 'lastname', object: null, titel: 'Nachname' },
      { name: 'telefon', object: null, titel: 'Tel.' },
      { name: 'mobile', object: null, titel: 'Mobile' },
      { name: 'email', object: null, titel: 'eMail' },
      { name: 'street', object: null, titel: 'Strasse' },
      { name: 'postalcode', object: null, titel: 'PLZ' },
      { name: 'city', object: null, titel: 'Ort' }
    ];
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private getValue1(def, entry): Promise<any> {
    return Promise.resolve('test');
  }
  private getValue(def, entry): Promise<any> {
    const that = this;
    return new Promise((resolve, reject) => {
      if (entry[def.name] === 'undefined') {
        return resolve('');
      }
      if (entry[def.name] === true) {
        return resolve('Ja');
      }
      if (entry[def.name] === false) {
        return resolve('Nein');
      }
      /*
      if (entry[def.name].indexOf('.') !== -1){
        return resolve(entry[def.name]);
      }
      if (entry[def.name].indexOf('#') !== -1){
        return resolve(entry[def.name]);
      }
      if (entry[def.name].indexOf('$') !== -1){
        return resolve(entry[def.name]);
      }
      if (entry[def.name].indexOf('[') !== -1){
        return resolve(entry[def.name]);
      }
      if (entry[def.name].indexOf(']') !== -1){
        return resolve(entry[def.name]);
      }*/

      switch (def.object) {
        case 'age':
          if (!entry.birthdate) {
            return resolve('');
          }
          if (entry.birthdate === 'undefined') {
            return resolve('');
          }
          if (entry.birthdate._isAMomentObject === true) {
            // value = value.format('YYYY-MM-DD');
          }

          if (entry.birthdate._isAMomentObject === false) {
            if (entry.birthdate.indexOf('.') !== -1) {
              return resolve('');
            }
          }

          const ageDec = moment().diff(entry.birthdate, 'years', true);
          const ageStr = Math.floor(ageDec).toString();

          return resolve(ageStr);

        case 'language':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getLanguageList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().language);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'module':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getModuleList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'foreign-classDetails-module':
          if (!entry.foreign || !entry.foreign.classDetails || !entry.foreign.classDetails[def.name]) {
            return resolve('');
          }
          that.ds
            .getModuleList()
            .child(entry.foreign.classDetails[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry.foreign.classDetails[def.name]);
              }
            });
          break;
        case 'competenceprofile-module':
          if (!entry.empfehlung.empfehlunglernangebotdurchlehrperson) {
            return resolve('');
          }
          if (entry.empfehlung.empfehlunglernangebotdurchlehrperson === '') {
            return resolve('');
          }
          if (
            !entry.empfehlung[def.name] ||
            entry.empfehlung[def.name].substr(0, 1)! !== '-' ||
            /[\.,#$\[\]]+/m.test(entry.empfehlung[def.name])
          ) {
            return resolve(entry.empfehlung[def.name]);
          }
          that.ds
            .getModuleList()
            .child(entry.empfehlung[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry.empfehlung[def.name]);
              }
            });
          break;
        case 'nationality':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getNationalityList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'class':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getClassList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'foreign-classperson-class':
          if (!entry.foreign || !entry.foreign.classperson || !entry.foreign.classperson[def.name]) {
            return resolve('');
          }
          that.ds
            .getClassList()
            .child(entry.foreign.classperson[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry.foreign.classperson[def.name]);
              }
            });
          break;
        case 'course':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getCourseList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'residencestatus':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getResidencestatusList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        /*case 'dolmetscher':
        if (!entry[def.name]){
            return resolve('');
          };
          that.ds.getUseraccountList().child(entry[def.name]).once('value').then((data)=>{
            if (data.val()){
              return resolve(data.val().name);
            }else{
              return resolve(entry[def.name]);
            }
          });
          break;*/
        case 'supervisor':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().firstname + ' ' + data.val().lastname);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'foreign-personDetails-supervisor':
          if (!entry.foreign || !entry.foreign.personDetails || !entry.foreign.personDetails[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry.foreign.personDetails[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().firstname + ' ' + data.val().lastname);
              } else {
                return resolve(entry.foreign.personDetails[def.name]);
              }
            });
          break;

        case 'teacher':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().firstname + ' ' + data.val().lastname);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'foreign-classDetails-teacher':
          if (!entry.foreign || !entry.foreign.classDetails || !entry.foreign.classDetails[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry.foreign.classDetails[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().firstname + ' ' + data.val().lastname);
              } else {
                return resolve(entry.foreign.classDetails[def.name]);
              }
            });
          break;
        case 'user':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'classperson':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getPersonList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'waitinglist':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getWaitinglistList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;
        case 'useraccount':
          if (!entry[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry[def.name]);
              }
            });
          break;

        case 'section':
          if (entry.sections[def.name]) {
            return resolve(entry.sections[def.name].presenceText);
          } else {
            return resolve(entry[def.name]);
          }
        case 'unexcused':
          if (entry.foreign && entry.foreign.calculation) {
            return resolve(entry.foreign.calculation[def.name]);
          } else {
            return resolve(entry[def.name]);
          }

        case 'attendance':
          if (entry.foreign && entry.foreign.calculation) {
            return resolve(entry.foreign.calculation[def.name]);
          } else {
            return resolve(entry[def.name]);
          }

        case 'classdetail-year':
          if (entry.foreign && entry.foreign.classDetails) {
            return resolve(entry.foreign.classDetails.year);
          } else {
            return resolve(entry[def.name]);
          }

        case 'classdetail-semester':
          if (entry.foreign && entry.foreign.classDetails) {
            return resolve(entry.foreign.classDetails.semester);
          } else {
            return resolve(entry[def.name]);
          }

        case 'classperson-class':
          if (entry.foreign && entry.foreign.classperson) {
            if (!entry.foreign.classperson.class) {
              return resolve('');
            }
            that.ds
              .getClassList()
              .child(entry.foreign.classperson.class)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return resolve(data.val().name);
                } else {
                  return resolve(entry[def.name]);
                }
              });
          } else {
            return resolve(entry[def.name]);
          }
          break;
        case 'classdetails-teacher':
          if (entry.foreign && entry.foreign.classDetails) {
            if (!entry.foreign.classDetails.teacher) {
              return resolve('');
            }

            that.ds
              .getUseraccountList()
              .child(entry.foreign.classDetails.teacher)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return resolve(data.val().firstname + ' ' + data.val().lastname);
                } else {
                  return resolve(entry[def.name]);
                }
              });
          } else {
            return resolve(entry[def.name]);
          }
          break;
        case 'classperson-module':
          if (entry.foreign && entry.foreign.classDetails) {
            if (!entry.foreign.classDetails.module) {
              return resolve('');
            }

            that.ds
              .getModuleList()
              .child(entry.foreign.classDetails.module)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return resolve(data.val().name);
                } else {
                  return resolve(entry[def.name]);
                }
              });
          } else {
            return resolve(entry[def.name]);
          }
          break;
        case 'competenceprofile-comment':
          if (entry.foreign && entry.foreign.competenceprofile) {
            return resolve(entry.foreign.competenceprofile.zufriedenheit.bemerkunganbetreuer);
          }
          break;
        case 'competenceprofile-recommendationLernangebot':
          if (!entry.foreign.competenceprofile) {
            return resolve('');
          }
          if (entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson === '') {
            return resolve('');
          }
          if (
            !entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson ||
            entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson.substr(0, 1)! !== '-' ||
            /[\.,#$\[\]]+/m.test(entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson)
          ) {
            return resolve(entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson);
          }
          that.ds
            .getModuleList()
            .child(entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson)
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry.foreign.competenceprofile.empfehlung.empfehlunglernangebotdurchlehrperson);
              }
            });
          break;

        case 'competenceprofile-recommendationNiveau':
          if (entry.foreign && entry.foreign.competenceprofile) {
            return resolve(entry.foreign.competenceprofile.empfehlung.empfehlungklassenniveaudurchlehrperson);
          }
          break;

        case 'classperson-active':
          if (entry.foreign && entry.foreign.classperson) {
            return resolve(entry.foreign.classperson.active);
          } else {
            return resolve(entry[def.name]);
          }
        case 'waitinglist-module':
          if (entry.waitinglist) {
            if (!entry.waitinglist.module) {
              return resolve('');
            }

            that.ds
              .getModuleList()
              .child(entry.waitinglist.module)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return resolve(data.val().name);
                } else {
                  return resolve(entry[def.name]);
                }
              });
          } else {
            return resolve(entry[def.name]);
          }
          break;

        case 'waitinglist-key':
          if (entry.waitinglist) {
            if (!entry.waitinglist.key) {
              return resolve('');
            }

            return resolve(entry.waitinglist.key);
          } else {
            return resolve(entry[def.name]);
          }

        case 'person':
          if (entry.person) {
            return resolve(entry.person[def.name]);
          } else {
            return resolve('');
          }
        case 'personCompetence':
          if (entry.personCompetence) {
            return resolve(entry.personCompetence[def.name]);
          } else {
            return resolve('');
          }

        case 'person-residencestatus':
          if (!entry.person || !entry.person[def.name]) {
            return resolve('');
          }
          that.ds
            .getResidencestatusList()
            .child(entry.person[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry.person[def.name]);
              }
            });
          break;
        case 'person-supervisor':
          if (!entry.person || !entry.person[def.name]) {
            return resolve('');
          }
          that.ds
            .getUseraccountList()
            .child(entry.person[def.name])
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().firstname + ' ' + data.val().lastname);
              } else {
                return resolve(entry.person[def.name]);
              }
            });
          break;
        case 'personCompetence-empfehlunglernangebot':
          if (!entry.personCompetence || !entry.personCompetence.empfehlunglernangebot) {
            return resolve('');
          }
          that.ds
            .getModuleList()
            .child(entry.personCompetence.empfehlunglernangebot)
            .once('value')
            .then((data) => {
              if (data.val()) {
                return resolve(data.val().name);
              } else {
                return resolve(entry.personCompetence.empfehlunglernangebot);
              }
            });
          break;

        case 'waitinglist-list':
          if (!entry.waitinglist) {
            return resolve('');
          }
          return resolve(
            entry.waitinglist
              .map((item) => {
                return item.name;
              })
              .toString()
          );

        case 'currentClassModule-list':
          if (!entry.currentClass) {
            return resolve('');
          }
          const promise = entry.currentClass.map((item) => {
            if (!item.module) {
              return '';
            }
            return that.ds
              .getModuleList()
              .child(item.module)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return data.val().name;
                } else {
                  return entry[def.name];
                }
              });
          });

          Promise.all(promise).then((data) => {
            resolve(data.toString());
          });
          break;

        case 'currentClass-list':
          if (!entry.currentClass) {
            return resolve('');
          }
          return resolve(
            entry.currentClass
              .map((item) => {
                return item.name;
              })
              .toString()
          );

        case 'currentClassTeacher-list':
          if (!entry.currentClass) {
            return resolve('');
          }
          const promise1 = entry.currentClass.map((item) => {
            if (!item.teacher) {
              return '';
            }
            return that.ds
              .getUseraccountList()
              .child(item.teacher)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return data.val().firstname + ' ' + data.val().lastname;
                } else {
                  return entry[def.name];
                }
              });
          });

          Promise.all(promise1).then((data) => {
            resolve(data.toString());
          });
          break;

        case 'nextClassModule-list':
          if (!entry.nextClass) {
            return resolve('');
          }
          const promise2 = entry.nextClass.map((item) => {
            if (!item.module) {
              return '';
            }
            return that.ds
              .getModuleList()
              .child(item.module)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return data.val().name;
                } else {
                  return entry[def.name];
                }
              });
          });

          Promise.all(promise2).then((data) => {
            resolve(data.toString());
          });
          break;

        case 'nextClass-list':
          if (!entry.currentClass) {
            return resolve('');
          }
          return resolve(
            entry.currentClass
              .map((item) => {
                return item.name;
              })
              .toString()
          );

        case 'nextClassTeacher-list':
          if (!entry.nextClass) {
            return resolve('');
          }
          const promise3 = entry.nextClass.map((item) => {
            if (!item.teacher) {
              return '';
            }
            return that.ds
              .getUseraccountList()
              .child(item.teacher)
              .once('value')
              .then((data) => {
                if (data.val()) {
                  return data.val().firstname + ' ' + data.val().lastname;
                } else {
                  return entry[def.name];
                }
              });
          });

          Promise.all(promise3).then((data) => {
            resolve(data.toString());
          });
          break;

        case 'deep':
          return resolve(that.get_deep_value(entry, def.name));

        case 'date':
          return resolve(moment(entry[def.name]).format('DD.MM.YYYY'));

        case 'timestamp':
          // console.log(entry[def.name].seconds)
          if (!entry[def.name]) {
            return resolve('');
          }
          if (!entry[def.name].seconds) {
            return resolve('');
          }
          return resolve(moment(entry[def.name].seconds * 1000).format('DD.MM.YYYY'));

        case 'time':
          return resolve(moment(entry[def.name]).format('HH:mm'));

        case 'dolmetscher':
          if (!entry[def.name]) {
            return resolve('');
          }
          return entry[def.name]
            .then((data) => {
              if (data) {
                return resolve(data.firstname + ' ' + data.lastname);
              } else {
                return resolve(entry[def.name]);
              }
            })
            .catch((err) => {
              console.log(entry[def.name]);
              return resolve(entry[def.name]);
            });
        case 'dolmetscher-attr':
          if (!entry[def.ref]) {
            return resolve('');
          }
          return entry[def.ref]
            .then((data) => {
              if (data) {
                return resolve(data[def.attr]);
              } else {
                return resolve(entry[def.ref]);
              }
            })
            .catch((err) => {
              console.log(entry[def.ref]);
              return resolve(entry[def.ref]);
            });

        case 'education':
          switch (entry[def.name]) {
            case '0':
              return resolve('ohne Ausbildung');
            case '10':
              return resolve('in Ausbildung');
            case '20':
              return resolve('Zertifiziert');
            case '30':
              return resolve('Eidg. Fachausweis');

            default:
              return resolve('ohne Ausbildung');
          }
        default:
          if (!entry[def.name]) {
            return resolve('');
          }
          return resolve(entry[def.name]);
      }
    }); // Promise
  }
  private resolveReference(ref) {
    if (!ref) {
      return null;
    }
    if (ref === '') {
      return null;
    }
    if (!ref.get()) {
      return null;
    }
    // console.log(ref)
    const data = ref
      .get()
      .then((doc) => {
        return { id: doc.id, ...doc.data() };
      })
      .catch((err) => {
        return {};
      });
    return data.then((i) => {
      // console.log(i)
      return i;
    });
  }

  private get_deep_value(obj, path): any {
    if (!obj) {
      return '';
    }
    for (let i = 0, path2 = path.split('.'), len = path2.length; i < len; i++) {
      if (!obj) {
        return '';
      }
      obj = obj[path2[i]];
    }
    return obj;
  }
}
